<template lang="html">
    <section>
        <v-row class="form">
            <v-col md="6">
                <v-form v-model="valid" ref="form">
                    <v-card class="pa-5">
                        <VCardTitle primary-title>מחולל האפליקציות של CloseApp</VCardTitle>

                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="form.appName" :rules="requiredRule" label="שם האפליקציה"></v-text-field>
                                <v-text-field v-model="form.address" :rules="requiredRule" label="כתובת האתר" @blur="extractDomain()" hint="כתובת האתר שהאפליקציה תטען"></v-text-field>

                                <VBtn color="primary" class="ma-5" @click="create">צור</VBtn>
                                <VAlert type="info" v-if="state == 'process'">מכין אפליקציה....</VAlert>
                                <VAlert type="success" v-if="state == 'done'">האפליקציה נוצרה בהצלחה!</VAlert>
                                <VAlert type="error" v-if="state == 'error'">שגיאה ביצירת האפליקציה</VAlert>
                                <VAlert type="error" v-if="state == 'inputError'">נא למלא את כל השדות</VAlert>
                            </v-col>

                            <div class="avatar-upload">
                                אייקון האפליקציה
                                <div class="avatar-edit">
                                    <input @change="iconChoosen" type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
                                    <label for="imageUpload"></label>
                                </div>
                                <div class="avatar-preview">
                                    <div id="imagePreview" :style="'background-image: url(' + iconUrl +');'">
                                    </div>
                                </div>
                            </div>
                        </v-row>



                    </v-card>


                    <v-expansion-panels class="mt-5">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                אפשרויות מתקדמות
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="form.appId" :rules="requiredRule" label="מזהה אפליקציה" hint="מזהה יחודי וקבוע לאפליקציה"></v-text-field>
                                        <v-checkbox v-model="form.fullScreen" label="מסך מלא"></v-checkbox>
                                        <v-checkbox v-model="form.alwaysAwake" label="מונע מצב שינה"></v-checkbox>
                                        <v-checkbox v-model="form.autoStart" label="הפעלה אוטמטית באתחול"></v-checkbox>
                                        <v-radio-group v-model="form.direction" row label="מצב המסך">
                                            <v-radio label="רגיל" value="0"></v-radio>
                                            <v-radio label="עומד" value="2"></v-radio>
                                            <v-radio label="שוכב" value="1"></v-radio>
                                        </v-radio-group>
                                        <v-text-field v-model="form.onesignal" label="מזהה OneSignal" hint="מזהה עבור התראות onesignal - אופציונלי"></v-text-field>

                                        <v-checkbox v-model="form.withADs" label="עם פרסומות" value="true"></v-checkbox>
                                        <v-text-field v-model="form.mobAdApp" label="מזהה MobAd אפליקציה" hint="מזהה עבור פרסומות - אפליקציה"></v-text-field>
                                        <v-text-field v-model="form.mobAdBanner" label="מזהה MobAd באנר" hint="מזהה עבור פרסומות - באנר"></v-text-field>

                                    </v-col>
                                    <v-col cols="6" class="d-flex flex-column align-center">
                                        צבע האפליקציה
                                        <v-color-picker @input="colorPicked" class="align-self-center" dot-size="25" swatches-max-height="200"></v-color-picker>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                    </v-expansion-panels>


                </v-form>
            </v-col>
        </v-row>
    </section>
</template>

<script lang="js">
const axios = require('axios');
const psl = require('psl');

export default {
    name: 'app-form',
    props: [],
    mounted() {

    },
    data() {
        return {
            form: {},
            icon: null,
            iconUrl: "",
            state: "",
            valid: true,
            requiredRule: [
                v => !!v || 'חובה למלא שדה זה'
            ]
        }
    },
    methods: {
        create() {
            if (!this.$refs.form.validate()) {
                this.state = "inputError"
                return
            }
            this.state = "process"
            const v = this
            this.extractDomain()
            const formData = new FormData();

            for (var key in this.form) {
                formData.append(key, this.form[key]);
            }

            formData.append('icon', this.icon);

            if (this.form.direction == 1) {
                formData.append('DIRECTION_LANDSCAPE', true);
            } else if (this.form.direction == 2) {
                formData.append('DIRECTION_PORTRAIT', true);
            }

            this.$store.dispatch('createApp',
                formData)
            this.$router.push('apps')
            // , {
            //     responseType: 'blob',
            //     headers: { 'Content-Type': 'multipart/form-data' }
            // })
            // .then(function(response) {

            //     const url = window.URL.createObjectURL(new Blob([response.data]));
            //     const link = document.createElement('a');
            //     link.href = url;
            //     link.setAttribute('download', 'app.apk');
            //     document.body.appendChild(link);
            //     link.click();

            //     v.state = "done"
            // })
            // .catch(function(error) {
            //     v.state = "error"
            // });
        },
        extractDomain() {
            const url = this.form.address.startsWith("http") ? new URL(this.form.address) : new URL("https://" + this.form.address)
            this.form.domain = psl.parse(url.host).domain
            this.form.path = url.pathname.substr(1)
            if (!this.form.appId) {

                this.form.appId = this.form.domain.split(".").reverse().join(".").replaceAll("-", "")
                this.$forceUpdate()
            }
        },
        iconChoosen(event) {
            const v = this
            this.icon = event.target.files[0];
            var reader = new FileReader();
            reader.onload = function(e) {
                v.iconUrl = e.target.result;
            }
            reader.readAsDataURL(event.target.files[0]);
        },
        colorPicked(e) {
            this.form.color = e.hexa
        }
    },
    computed: {

    }
}
</script>

<style scoped>
.form {
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 50px auto;
}

.avatar-upload .avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
}

.avatar-upload .avatar-edit input {
    display: none;
}

.avatar-upload .avatar-edit input+label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #ffffff;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input+label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input+label:after {
    content: "\f040";
    font-family: "FontAwesome";
    color: #757575;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
}

.avatar-upload .avatar-preview {
    width: 192px;
    height: 192px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #f8f8f8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: lightgray;
}

.avatar-upload .avatar-preview>div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
</style>
