import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

Vue.use(Vuex)

const BASE_URL = '/api'

const config = {
    state: {},
    getters: {
        signedUser: state => state.user?.email, 
    },
    mutations: {
        setUser(state, user) {
            state.user = user
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + user?.apiKey
            localStorage.setItem("token", user.apiKey)
            router.push('apps')
        }
    },
    actions: {
        async createApp(store, app) {
            if (!store.state.user?.apiKey) {
                await axios.post(`${BASE_URL}/sessions`, { "anonymous": true })
                            .then(u => store.commit('setUser', u.data))
            }
            axios.post(`${BASE_URL}/apps`, app)
        },
        loadApps(store) {
            axios.get(`${BASE_URL}/apps`).then(u => store.commit('setApps', u.data))
        },
        login(store, c) {
            axios.post(`${BASE_URL}/sessions`, c).then(u => store.commit('setUser', u.data)).then(() => router.push('apps'))
        },
        register(store, c) {
            if (!store.getters.signedUser) {
                axios.put(`${BASE_URL}/users/me`, c).then(u => store.commit('setUser', u.data)).then(() => router.push('apps'))
            } else {
                axios.post(`${BASE_URL}/users`, c).then(u => store.commit('setUser', u.data)).then(() => router.push('apps'))
            }
        },
        logout(store) {
            store.state.user = null
            localStorage.removeItem("token")
            router.push('login')
        },
        regenApp(store, appId) {
            axios.post(`${BASE_URL}/apps/${appId}/regen`).then(u => store.commit('setApps', u.data))
        }
    },
    modules: {}
}

const toUpperFirst = str => str[0].toUpperCase() + str.substring(1)

const models = [
    "user",
    "apps"
];

for (var model of models) {
    config.state[model] = [];
    config.getters[model] = eval("(state) => state." + model)
    config.mutations["set" + toUpperFirst(model)] ||= eval("(state, modelres) => state." + model + " = modelres");
}

const store = new Vuex.Store(config)


axios.defaults.headers.common['Authorization'] = 'Bearer ' +
    localStorage.getItem("token")

axios.get(`${BASE_URL}/users/me`).then(u => store.commit('setUser', u.data))

export default store;
