<template>
    <v-app>
        <div id="app">
            <v-app-bar color="primary" class="flex-grow-0" dark>
                <v-app-bar-nav-icon></v-app-bar-nav-icon>
                <router-link to="/apps">
                    <v-toolbar-title>מגנרט אפליקציות</v-toolbar-title>
                </router-link>
                <v-spacer />
                <v-btn to="/" color="success" class="ml-3"><i class="fa fa-create"></i> צור אפליקציה חדשה
                </v-btn>
                <v-btn v-if="user" to="/apps" color="success" class="ml-3"><i class="fa fa-create"></i> האפליקציות שלי
                </v-btn>
                <v-btn v-if="!user" to="login" color="secondary" class="ml-3"><i class="fa fa-key"></i> התחברות
                </v-btn>
                <v-btn v-if="user" @click="logout" color="error" class="ml-3"><i class="fa fa-key"></i> התנתק
                </v-btn>
                <v-spacer />
            </v-app-bar>
            <router-view />
        </div>
    </v-app>
</template>

<script lang="js">
export default {
    name: 'app',
    props: [],
    methods: {
        logout() {
            this.$store.dispatch('logout')
        }
    },
    computed: {
        user() {
            return this.$store.getters.signedUser
        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: #a3ceff;
    height: 100%;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}

a {
    color: white;
    text-decoration: none;
}
</style>
